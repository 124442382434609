@import url('https://fonts.googleapis.com/css2?family=Hind+Vadodara:wght@300;400;500;600;700&display=swap');

body{
  background-color: #fff;
  color: #3A393F;
  font-family: "Hind Vadodara", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 0;
}

a{
    color: #1c4ac3;
}

.plan-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 40px;
    /*margin-top: 20px;*/
    gap:  20px;
}

.plan-header .page-title {
    font-size: 26px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    color: #000;
}

.plan-header .page-subtitle {
    margin: 0;
    font-size: 15px;
    font-weight: 400;
    padding-top: 5px;
}

.plan-limit {
    width: 300px;
}

.plan-limit .plan-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
        margin-bottom: 10px;
}

.plan-limit .plan-top h6 {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
}

.refresh-icon {
    cursor: pointer;
    margin-right: 10px;
}
  
.refresh-icon.spinning {
    animation: spin 2s linear infinite;
  }

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.plan-limit .progress-bar {
    background: #e7e7e7;
    height: 6px;
    position: relative;
}

.plan-limit .progress-bar .progress-percentage {
    position: absolute;
    width: 100%;
    background: #099eff;
    height: 100%;
}

.payment-plan-container {
    max-width: 1100px;
    margin: auto;
    padding: 20px;
}

.header-wrap {
    padding: 15px 0px;
}

.ai-payment-wrapper {
    padding: 50px;
}

.payment-plan-wrapper {
    display: flex;
    /*max-width: 1100px;*/
    margin: auto;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
    min-height: 600px;
}

.checks-list ul {
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    gap: 15px 25px;
    margin: 0px;
}

.checks-list ul li {
    position: relative;
    font-size: 14px;
    flex-basis: 100%;
    padding-left: 25px;
    color: #3A393F;
}

.checks-list ul li:before {
    content: 'done';
    font-family: 'Material Symbols Rounded';
    position: absolute;
    width: 20px;
    height: 20px;
    background-image: url(../images/check.svg);
    left: 0;
    background-repeat: no-repeat;
    background-position: center;
    top: 2px;
    font-size: 20px;
    line-height: 20px;
}

.plan-card {
    box-sizing: border-box;
    border: 1px solid #E6E6E6;
    border-radius: 20px;
}

.price-tab {
    position: relative;
    display: flex;
    background: #F3F4F6;
    padding: 6px;
    border-radius: 6px;
}

.price-wrap h4 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    color: #000;
}

.price-wrap h2 {
    font-size: 50px;
    margin: 0;
    padding-top: 0;
    color: #000;
    font-weight: 500;
}

.price-wrap h2 sub {
    position: relative;
    font-size: 24px;
    font-weight: 600;
    top: -13px;
    padding-left: 5px;
}

.price-wrap h2 sup {
    font-size: 32px;
    font-weight: 600;
    margin-right: 5px;
    top: -8px;
}
    
.price-wrap {
    padding: 5px 0;
}

.button-wrap {
    position: relative;
}

.button-wrap .card-cta {
    width: 100%;
    height: 50px;
    font-size: 16px;
    border-radius: 10px;
    border: 0;
    background-color: #1c4ac3;
    color: #fff;
    font-family: "Hind Vadodara", sans-serif;
    cursor: pointer;
}

.button-wrap .card-cta-red {
    width: 100%;
    height: 50px;
    font-size: 16px;
    border-radius: 10px;
    border: 0;
    background-color: #ff0410a8;
    color: #fff;
    font-family: "Hind Vadodara", sans-serif;
    cursor: pointer;
}

.button-wrap h6 {
    font-size: 13px;
    font-weight: 300;
    color: #808080;
    margin: 0;
    padding-top: 20px;
}

.button-wrap .card-cta:hover {
    background: #047fcc;
}

.button-wrap .card-cta-red:hover {
    background: #ff0404;
}

.button-wrap .note-text {
    margin-top: 20px;
}

.note-text {
    font-size: 13px;
    color: #808080;
}

@media screen and (max-width:  992px){

    .ai-payment-wrapper {
        padding: 20px 25px;
    }

    .checks-list ul li{
        flex-basis: 100%;
    }

    .payment-plan-wrapper .panel-right {
        flex: 1;
        max-width: 320px;
    }

    .price-wrap h2{
        font-size: 42px;
    }

}

@media screen and (max-width:  667px){

    .payment-plan-wrapper .panel-left {
        flex-basis: 100%;
    }

    .payment-plan-wrapper {
        flex-direction: column-reverse;
        align-items: center;
    }

}

@media screen and (min-width:  992px) and (min-height: 700px){
    .ai-payment-wrapper {
        padding: 0 30px;
        height: 600px;
        display: flex;
        align-items: center;
    }
}

@media screen and (min-width:  992px) and (min-height: 800px){
    .ai-payment-wrapper {
        padding: 0 30px;
        height: 700px;
        display: flex;
        align-items: center;
    }
}

.best-deal {
    background: #FDF7DC;
    padding: 4px 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    color: #7C652D;
    margin-bottom: 5px;
    display: inline-block;
    margin-top: 0;
    text-transform: uppercase;
}

.plan-card .plan-card-header {
    position: relative;
    padding: 20px 25px 10px 25px;
    border-bottom: 1px solid #E6E6E6;
}

.plan-card .plan-card-body {
    padding: 25px;
    position: relative;
}

.plan-card .plan-card-body .button-wrap {
    margin-top: 30px;
}

.card-list-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}

.card-list-wrap .card-item {
    flex: 1;
}

.card-list-wrap .card-item .plan-card {
    height: 100%;
}

.plan-card .plan-card-body .checks-list {
    min-height: 245px;
    position: relative;
}

@media screen and (max-width: 576px){
    .plan-limit {
        width: 100%;
    }
    .plan-card .plan-card-body .checks-list {
        min-height: auto;
    }

    .card-list-wrap .card-item {
        flex: 1;
        max-width: 100%;
        flex-basis: 100%;
    }

    .plan-header .item {
        flex-basis: 100%;
    }

}

@media screen and (min-width: 576px) and (max: 992px) and (orientation: landscape){
    .plan-card .plan-card-body .checks-list {
        min-height: 250px;
    }

}

/*new added css*/

.card-list-wrap.card-list-single {
    max-width: 1000px;
    margin: auto;
}

.card-list-wrap.card-list-single .plan-card {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    flex-direction: row-reverse;
    padding: 30px;
}

.card-list-wrap.card-list-single  .checks-list ul li {
    flex-basis: calc(50% - 38px);
}

.card-list-wrap.card-list-single  .checks-list ul {
    gap: 15px 20px;
}

.card-list-wrap.card-list-single .plan-card .plan-card-body .checks-list h4 {
    margin-top: 0;
    margin-bottom: 15px;
}

.card-list-wrap.card-list-single .plan-card .plan-card-header {
    border-bottom: 0;
    border-left: 1px solid #E6E6E6;
    max-width: 320px;
    padding: 20px 50px;
    flex: 1;

}

.card-list-wrap.card-list-single .plan-card .plan-card-body {
    flex: 1;
        padding: 0 25px;
}

.card-list-wrap.card-list-single .plan-card .plan-card-body .checks-list {
    min-height: auto;
}

@media screen and (max-width: 992px){
    .card-list-wrap.card-list-single .checks-list ul li {
        flex-basis: calc(100% - 0px);
    }
}

@media screen and (max-width: 576px){

    .card-list-wrap.card-list-single .plan-card .plan-card-header {
        flex-basis: 100%;
    }

    .card-list-wrap.card-list-single .plan-card .plan-card-body {
        flex: 1;
        padding: 25px;
    }

    .card-list-wrap.card-list-single .plan-card .plan-card-header {
        flex-basis: 100%;
        padding: 25px;
        border-left: 0 !important;
        border-left: 1px solid #E6E6E6;
        border-bottom: 1px solid #E6E6E6;
    }

    .card-list-wrap.card-list-single .plan-card {
        padding: 0;
        gap: 0;
    }

    .card-list-wrap.card-list-single .plan-card .plan-card-header {
        max-width: 100%;
    }

}

.site-header {
    background: white;
    box-sizing: border-box;
    box-shadow: 1px 5px 8px rgb(0 0 0 / 6%);
    position: sticky;
    width: 100%;
    z-index: 1060;
    top: 0;
}

.site-header .inner-container {
    max-width: 1140px;
    margin: auto;
    display: flex;
    align-items: center;
}

.site-header a.nav-link {
    color: #454545;
    padding: 10px 15px !important;
    font-size: 15px;
}

.site-header .nav-item:last-child .nav-link {
    padding-right: 0 !important;
}

.site-header a.nav-link:hover,
.site-header a.nav-link.active {
    color: #000;
}


.site-header  .navbar-toggler {
    border: 0;
    padding: 0;
}

.site-header .navbar-toggler:focus {
    box-shadow: none;
    outline: 0;
    border: 0;
}

@media screen and (max-width:  576px){

    .navbar-collapse {
        margin-top: 15px;
    }

    .navbar-collapse a.nav-link {
        padding: 10px 0 !important;
    }
   
}

.menu-items {
    margin-left: auto;
}

.menu-items ul {
    display: flex;
    margin: 0;
    gap: 20px;
    list-style: none;
}

.menu-items ul li {}

.menu-items ul li  a {
    color: #333;
    text-decoration: none;
    font-size: 15px;
}

.menu-items ul li a:hover {
    color: #1c4ac3;
}

.main {
    padding: 15px;
    padding-top: 50px;
}

/*footer*/

footer.footer {
    border-top: 1px solid #f3f3f3;
}

.footer-list {
    display: inline-flex;
    flex-wrap: wrap;
    list-style-type: none;
    gap: 3px;
    padding: 0;
    width: 100%;
    /*margin: 15px 0;*/
    justify-content: center;
}

@media screen and (max-width:  992px){
    .footer-list{
        margin: 15px 0;
    }
}

.footer-list li a {
    font-weight: 300;
    text-decoration: none;
    font-size: 14px;
    color: #5d5d5d;
    border-right: 1px solid #cfcfcf;
    padding: 0 10px;
}

.footer-list li:last-child a {
    border-right: 0;
}

.footer-social-links {
    display: flex;
    justify-content: center;
    list-style-type: none;
    font-size: 0;
    gap: 10px;
    margin: 0;
    padding: 0;
}

.footer-social-links li{
    width: 34px;
    height: 30px;
    position: relative;
}

.footer-social-links li a{
    font-size: 0;
    width: 100%;
    height: 100%;
    background: #000;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    display: block;
}

.footer-social-links li a{
    background: url(https://images.appypie.com/wp-content/uploads/2021/05/20103216/faq-sprite.svg);
    mask-image: url(https://images.appypie.com/wp-content/uploads/2021/05/20103216/faq-sprite.svg);
    -webkit-mask-image: url(https://images.appypie.com/wp-content/uploads/2021/05/20103216/faq-sprite.svg);
    mask-repeat: no-repeat;
    background-repeat: no-repeat;
    mask-position:  10px 4px;
    -webkit-mask-position: 10px 4px;
    background: #515050;
}
    
.footer-social-links li.twitter a{      
    mask-position:  -20px 4px;
    -webkit-mask-position: -20px 4px;
}

.footer-social-links li.pinterest a{      
    mask-position:  -50px 4px;
    -webkit-mask-position: -50px 4px;
}

.footer-social-links li.blogger a{      
    mask-position:  -80px 4px;
    -webkit-mask-position: -80px 4px;
}

.footer-social-links li.linkedin a{      
    mask-position:  -110px 4px;
    -webkit-mask-position: -110px 4px;
}

.footer-social-links li.youtube a{      
    mask-position:  -143px 4px;
    -webkit-mask-position: -143px 4px;
}

.footer-social-links li.instagram a{      
    mask-position:  -177px 4px;
    -webkit-mask-position: -177px 4px;
}

.copyright-text {
    font-size: 13px;
    color: #9e9e9e;
    font-weight: 100;
    max-width: 800px;
    margin: auto;
}

.plan-card.active {
    border-color: #1c4ac3;
}

.current-plan-label {
    display: inline-block;
    padding: 9px 20px;
    border-radius: 0;
    color: #fff;
    font-weight: 600;
    text-align: center;
    margin-top: 10px;
    background: #1c4ac3;
    top: 0;
    position: absolute;
    top: -10px;
    right: -1px;
    border: 0;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 15px;
  }

  .loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    margin: 0 auto;
  }

  .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1060;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: #ffffffb2;
    backdrop-filter: blur(3px);
}

.loader-container .loader-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
  .loader-text {
    text-align: center; /* Center the text */
    margin-top: 20px; /* Add some space between the loader and the text */
  }

  .skip-price a{
    float: right; margin-top: 15px; margin-right: 15px; color: #d8dee1;
  }

  /*add on css*/

.plan-card .plan-card-header{
    padding: 30px 25px 20px 25px;
}


.dropdown.input-dropdown .dropdown-toggle {
  display: flex;
  align-items: center;
  padding: 0.4rem 1rem;
  font-size: 1rem;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.dropdown.input-dropdown .dropdown-toggle .icon-caret {
  margin-left: auto;
}


.dropdown-toggle::after {
    margin-left: 20px;
}

.dropdown .dropdown-menu {
    box-shadow: 0px 4px 14px rgb(0 0 0 / 16%);
    border-color: #D9D9D9;
}

.dropdown .dropdown-menu .dropdown-item {
    color: #24233E;
    font-size: 15px;
    padding: 6px 20px;
}


.basic-plan-title{
    background: #99C2FF;
    color: #fff;
}

.standard-plan-title{
    background: #FCB58C;
    color: #fff;
}

.pro-plan-title{
    background: #FC8C8C;
    color: #fff;
}

.plan-card.active {
    border-color: #366bf9;
}

.plan-card .plan-card-header span.badge {
    background: #E6E6E6;
    position: absolute;
    top: -1px;
    right: -1px;
    padding: 7px 14px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 0;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 10px;
    color: #ffffff;
}

.plan-card .plan-card-header {
    position: relative;
}

.plan-card.active span.badge {
    background: #366bf9;
}



.plan-limit .progress-bar .progress-percentage {
    background: #366bf9;
}

.button-wrap .card-cta {
    background: #356BF8;
    border: 1px solid #366bf9;
}

.button-wrap .card-cta:hover {
    background: #1c4ac3;
    border-color: #1c4ac3;
}

.button-wrap .card-cta-o {
    background: #fcb58c;
    border: 1px solid #fcb58c;
}

.button-wrap .card-cta-o:hover {
    background: #ef9765;
    border-color: #ef9765;
}

.card-cta-bordered {
    width: 100%;
    height: 50px;
    font-size: 16px;
    border-radius: 10px;
    background-color: #ffffff;
    color: #366bf9;
    font-family: "Hind Vadodara", sans-serif;
    cursor: pointer;
    border: 1px solid #366bf9;
    text-align: center;
    line-height: 50px;
    text-decoration: none;
}

.card-cta-bordered:hover{
    background: #366bf9;   
    color: #fff;
}


.anchor-group {
    display: flex;
    gap: 20px;
}

.anchor-group a {
    text-decoration: none;
    position: relative;
}

.anchor-group a:after {
    content: '';
    position: absolute;
    width: 1px;
    height: 15px;
    background: #366bf9;
    top: 52%;
    right: -10px;
    transform: translate(0px, -50%);
}

.anchor-group a:last-child:after {
    display: none;
}

@media screen and (max-width:  576px){
    .anchor-group a {
        font-size: 15px;
    }

    .dropdown-toggle::after{
        margin-left: 10px;
    }
}


.api-info-list p {
    margin: 0;
    font-size: 16px;
    color: #24233E;
}

.api-info-list .item {
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
    border: 1px solid #D5EEF5;
    align-items: center;
    margin: 15px 0;
    box-shadow: 0 4px 34px rgb(181 211 255 / 25%);
    border-radius: 10px;
    flex-wrap: wrap;
    gap: 15px;
    padding-right: 0;
}

.api-info-list .item a {
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    padding: 20px;
    background: #366bf9;
    color: #fff;
    min-width: 220px;
    text-align: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.api-info-list .item a:hover {
    background: #1f52db;
}

.api-info-list .item:last-child {
    border-bottom: 1px solid #E7E7E7;
}

@media screen and (max-width:  882px){
    .api-info-list .item {
        flex-direction: column;
        align-items: flex-start;
        padding: 20px;
    }

    .api-info-list .item a {
        min-width: auto;
        padding: 12px 15px;
        border-radius: 6px;
    }
}

.plan-card{
    background: #fff;
}

.plan-card .plan-card-header .badge.badge-top {
    background: #99c2ff;
    width: calc(100% + 2px);
    border-radius: 0;
    right: -1px;
    z-index: -1;
    top: -38px;
    padding-bottom: 45px;
    padding-top: 12px;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
}

.plan-card.recommended-plan .plan-card-header .badge.badge-top {
    background: #FCB58C;
}



.plan-card.recommended-plan{
    border-color: #FCB58C;
}

.price-wrap h2 sup{
    top: -18px;
}


@media screen and (max-width: 768px){
    .card-list-wrap .card-item {
        flex: 1;
        margin-bottom: 35px;
    }
}


@media screen and (max-width: 576px){
    .card-list-wrap .card-item {
        flex-basis: 100% !important;
    }
}


.api-button-row {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 20px;
}
.api-button-row .card-cta-bordered {
    flex: 1;
}

@media screen and (max-width: 667px) {
    .api-button-row .card-cta-bordered {
        flex-basis: 100%;
    }

    .api-button-row {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        margin-top: 20px;
    }

}


.best-deal.viewonly {
    visibility: hidden;
}

.card-cta-bordered.cta-free:hover {
    background: transparent;
    color: #356bf8;
}